import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Footer.css";
class Footer extends Component {
  render() {
    return (
      <div style={{paddingBottom:"140px"}}>
<div className="container">

  <div className="desktop_grid">
  <div className="row" >

    {/* <div className="col-md col-xs-6">
    <a className="footer_subhead_one" href="/privacy-policy">Privacy Policy</a>
  
    </div> */}
  </div>
  </div>
 {/* <div className="full_grid">
 <div className="half_grid">
    <a className="footer_subhead_one" href="/privacy-policy">Privacy Policy</a>
    </div>
 </div> */}
    <div className="footer_last_section">
      <p className="footer_subhead">©2024 frontiercableinternetdeals.com. All Rights Reserved. <a className="footer_subhead_one" href="/privacy-policy">Privacy Policy</a></p>
<p className="footer_subhead">Frontier Cable Internet Deals</p>
    </div>
    </div>
</div>
    );
  }
}

export default withRouter(Footer);
