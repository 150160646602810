
import React from "react";
import { withRouter } from "react-router-dom";
import "./Section6.css";
import { IoCall } from "react-icons/io5";


  const Section6 = ({ tfn, getTfnFormat, getTfn, handleClick }) => {
   
  return (
    <div className="blue_Section_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="blue_section_head">Extend your Wi-Fi signal starting at $10/mo♦</h2>
            <p className="blue_section_subhead">Eliminate dead spots by adding Whole-Home Wi-Fi extenders to your plan.</p>
           
            <div className="phonee_divv my-2">
                <a className="sec2_b" onClick={handleClick} href={tfn === null ? "tel:+18335256234": getTfn(tfn)}><span style={{display:"flex", alignItems:"center", gap:"0.5rem"}}><IoCall size={20} />CALL  {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</span></a>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Section6);
