import React, { useState, useEffect } from "react";
import Section1 from "../../Components/Section1/Section1";
import Section2 from "../../Components/Section2/Section2";
import Section8 from "../../Components/Section8/Section8";
import Section3 from "../../Components/Section3/Section3";
import Section4 from "../../Components/Section4/Section4";
import Section5 from "../../Components/Section5/Section5";
import Section6 from "../../Components/Section6/Section6";
import Section7 from "../../Components/Section7/Section7";
import { withRouter } from "react-router";
import "./Home.css";


const HomePage = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 900;

      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-525-6234";

      window.gtag('event', 'conversion', {
        'send_to': 'AW-11485870603/05alCPXArI4ZEIv08eQq',

        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });

    } else {
      console.error('Google Analytics gtag function is not defined.');
    }

  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);

  const setUrl = (url) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("tfn", url);
    let newurl =
      window.location.protocol +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
    window.scrollTo(0, 0);
    setTfn(url);
  };

  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };

  return (
    <>
      <Section1 tfn={tfn} handleClick={handleClick} getTfn={getTfn} getTfnFormat={getTfnFormat} />

      <Section2 tfn={tfn} handleClick={handleClick} getTfn={getTfn} getTfnFormat={getTfnFormat} />

      {isVisible && (
        <div className="section2">
          <Section2 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick} />
        </div>
      )}
      <Section4 />
      <Section6 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick} />
      <Section3 />
      <Section8 />
      <Section5 />
      <Section7 />
    </>
  );
};

export default withRouter(HomePage);
