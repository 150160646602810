import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Section1.css";
import section1_img from "../../Assets/Images/section1.png";
import { IoCall } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Section1 = ({tfn, getTfnFormat, getTfn , handleClick}) => {
  const numberToShow = tfn || "1-844-963-5015";

  
    return (
      <>
        <div className="sec1_con">
          <div className="container">
            <div className="space_divOne">
            <div className="row ">
              <div className="col-xl-6 col-sm-12 col-md-12 sec1_col1">
                <h1 className="sec1_h1">
                  FRONTIER INTERNET<sup>®</sup>
                </h1>
                <span className="sec1_span1">Fiber 1 Gig</span>
                <div>
                  <div className="sec1_price">
                    <h2>$59.99</h2>
                  </div>
                  <div className="sec1_content">
                    <p>
                      per month for 12 months w/ Auto Pay & Paperless Bill. Max
                      wired speed 1000/1000. Wi-Fi, actual & average speeds
                      vary. Early termination
                      <sup style={{ fontSize: "smaller", lineHeight: "1rem" }}>
                        ††
                      </sup>{" "}
                      & one-time charges apply. In select areas where available.
                    </p>
                  </div>
                </div>
                <div className="sec1_ulDiv mt-3">
                  <ul className="sec1_ul">
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Amazon eero Wi-Fi Pro 6 router included**
                    </li>
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Expert installation included ($100 value)
                    </li>
                    <li>
                      <IoAdd style={{ marginRight: "5px" }} />
                      Extreme bandwidth for smart homes with dozens of devices
                    </li>
                  </ul>
                </div>
                
                <div className="sec1_btn">
                <div className="phonee_divv my-2">
                <a className="sec2_b" onClick={handleClick}  href={tfn === null ? "tel:+18335256234": getTfn(tfn)}><span style={{display:"flex", alignItems:"center", gap:"0.5rem"}}><IoCall size={20} />CALL {tfn === null? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn)}</span></a>
                </div>
                {/* <button className="sec1_b px-4 py-3" onClick={handleButtonClick}><IoCall />  <span style={{padding:"5px"}}>Call Now {numberToShow}</span></button> */}
                  {/* <button className="sec1_b px-4 py-3">
                    <IoCall />
                    <span style={{padding:"10px"}}>CALL NOW 1-833-779-2132</span>
                  </button> */}
                </div>
              </div>
              <div className="col-xl-6 offset-md-12 sec1_imgCol">
              <LazyLoadImage  effect="blur" className="sec1_col2Img" src={section1_img} alt="banner" />
              </div>
            </div>
            </div>
          </div>
        </div>
      </>
    );
  }


export default withRouter(Section1);
