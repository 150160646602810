import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Section7.css";

class Section7 extends Component {
  handleLogoClick = () => {
    this.props.history.push("/");
  };
  render()

   {
    return (
    <div className="container">
      <p className="info_para">*Visa Reward Card Offers: Not redeemable for cash. 12-month pro-rated early termination fee will be applied by Frontier based on card amount if the optional Visa offer is selected. For new customers who maintain service and pricing offer without change for 90 days after install and pay first bill in full. Must redeem within 75 days of notification. Card delivered 30 days after redemption and expires 6 months after issuance. The Frontier Visa Reward Card is issued by The Bancorp Bank pursuant to a license from Visa U.S.A. Inc. and can be used everywhere Visa debit cards are accepted. No cash access. The Bancorp Bank; Member FDIC. The Bancorp Bank does not endorse or sponsor and is not affiliated in any way with any product or service offered by Frontier Communications.
<br/>
<br/>
**eero max speeds are derived from IEEE specifications. Coverage varies based on normal use and building conditions. Actual range and performance can vary. See https://eero.com/legal for more details. ©2023 Amazon.com, Inc. or its affiliates. eero and all related marks are trademarks of Amazon.com, Inc. or its affiliates. Amazon.com c/o eero LLC, 660 3rd St., San Francisco, CA.
<br/>
<br/>
***Maximum wireless transmission rates are the physical rates derived from IEEE Standard 802.11 specifications. Range, coverage, and maximum quantity of connected devices are based on test results under normal usage conditions. Actual wireless data throughput, wireless coverage, and quantity of connected devices are not guaranteed. Use of Wi-Fi 6E, and features including OFDMA, 1024-QAM, and HE160 requires clients to also support the corresponding features.
<br/>
<br/>
†Unlimited Digital Voice requires Internet service to function. If Internet is canceled customer must subscribe to Unlimited Voice (non-Digital) service to retain Voice service. Your Frontier Digital Voice service, including 911 service, requires electrical or battery backup power to function. During a power outage, you may not be able to make calls, including to 911, without backup power for the Residential Gateway (RG) and/or the Optical Network Terminal (ONT), or an alternate means of calling. Optional battery backup can be purchased separately at frontier.com/batterybackup. Includes direct dialed local and long distance calls to the US, Canada and Mexico. Other call types excluded. Restrictions apply.
<br/>
<br/>
¶Total Shield requires active Frontier internet service. The smartphone required for app download, notifications and operation is not provided by Frontier. Internet access service and charges not included. Frontier does not warrant that the service will be error-free or uninterrupted. Taxes, governmental and Frontier-imposed surcharges, minimum system requirements, and other terms and conditions apply. Multi-Device Security covers up to 10 devices.
<br/>
<br/>
‡Hours are subject to change. My Premium Tech Pro is a best effort service and may not always be able to solve every problem, despite best efforts by its technicians. For Fiber 5 Gig customers, the My Premium Tech Pro subscription is provided at no charge and required for as long as Fiber 5 Gig remains on the account.
<br/>
<br/>
♦Price includes up to two Wi-Fi extenders. Quantity and model determined based on existing router and coverage needs at time of order/installation. Coverage range and performance varies based on model, use and building conditions. Additional extenders available for $10/month each. Equipment non-return and restocking fees apply per internet service terms.
<br/>
<br/>
4Based on comparison of Frontier Fiber 5 Gig upload speed up to 5000 Mbps versus advertised cable upload speeds for Xfinity Gigabit plan of 35 Mbps, Spectrum Internet Gig plan of 35 Mbps, and Optimum 1 Gig Internet plan of 35 Mbps.
<br/>
<br/>
^For Frontier internet customers new to YouTube TV. This YouTube TV discount is only open to participants in the United States who have activated the offer from Frontier on or after October 1. Promotional offer only available to users who are not current YouTube TV subscribers, have not been YouTube TV subscribers or participated in a YouTube TV trial before. If eligible: Frontier Internet customers will receive the standard free trial, and then a discount of $10 per month for 12 months on the standard subscription price; or Frontier Internet with current Frontier TV or Fiber TV will receive the standard free trial, and then a discount of $15 per month for 12 months on the standard subscription price offered by YouTube TV. Standard subscription price is subject to change. You will not be charged until the trial period expires. You can cancel your standard trial at no charge at any time before the trial is over. You can also cancel your YouTube TV paid membership at any time. You will continue to receive access to YouTube TV until the end of your YouTube TV billing period, but refunds and credits are not issued for partial billing periods. Offer valid for one redemption of YouTube TV discount per eligible Frontier account. Eligible participants must use the activation link provided by Frontier and complete activation through Google to claim offer. Offer requires a Google account. Promotional value of offer is non-transferable, not for resale, non-redeemable for cash, and non-refundable. Must be 18 years or older. Free trial, offers, or promotional offers available only for YouTube TV Base Plan. Equipment, installation and other service charges, taxes and fees extra. Frontier Internet account required. Offer and billing subject to Frontier Terms and Conditions. Frontier reserves the right to change or cancel the offer at any time and for any reason. YouTube Paid Service Terms of Service also apply.
<br/>
<br/>
<div style={{textAlign:"center"}}>††$100 prorated early termination fee applies if cancelled months 1-12.</div>
<br/>
<br/>
Exclusive offer for qualified addresses. For new residential Internet customers only. Max speeds are wired, Fiber 1 Gig location dependent. Wi-Fi, actual & average speeds vary. Max speed capable range for Fiber 500 (450-500 Mbps download/upload), Fiber 1 Gig (846-1000 Mbps download, 792-1000 Mbps upload), Fiber 2 Gig (1800-2000 Mbps download/upload) and requires 2 Gbps capable devices and wiring. Fiber 5 Gig (4500-5000) and requires 5 Gbps capable devices and wiring. Requires Auto Pay or $10/mo fee applies. Printed bill available for $2.99/mo. Fee does not apply to NY, PA and select customers. $50 expert installation fee applies. A $50 equipment restocking fee per household applies when Internet is disconnected. A fee up to $150 per device for Fiber 500, 1 Gig, and 2 Gig, and up to $400 for Fiber 5 Gig also applies if equipment is not returned. Other applicable charges and additional services are extra. Subject to availability. Price and terms subject to change. Cannot be combined with other offers. Other restrictions, Frontier policies and service terms apply.
</p>
    </div>
    );
  }
}

export default withRouter(Section7);
