import React, {  useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./Section4.css";
import Banner from "../../Assets/Images/Home-banner.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Section4 = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
    return (
      <>
       {isDesktop ? (
      <div
        className="row mob"
        style={{ alignItems: "center", marginLeft:"-15px" }}
      >
        <div className="col-md-6 col-xl-6">
        <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner" />
        </div>
        <div className="col-md-6"> 
          <div className="container section_space">
            <h1 className="heading_section">
              Bundle Frontier Internet service with home phone
            </h1>
            <p className="paragraph_section">
              Add home phone to Frontier Internet plans to build out your home
              business hub, stay tapped into the citywide emergency grid, or
              revisit the pleasure of a retro telephone set. Then pivot to the
              futuristic speeds of Frontier Fiber. It’s all available on one
              bill when you bundle Frontier Internet and home phone today.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="planButton">Shop Frontier Bundles</button>
            </div>
           </div>
         </div> 
      </div>
       ):(
        <div className="container">
        <div
        className="row mob"
        style={{ alignItems: "center", marginBottom: "30px", marginLeft:"-15px" }}
      >
        <div className="col-md-6 col-xl-6">
        <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner" />
        </div>
        <div className="col-md-6"> 
          <div className="container">
            <h1 className="heading_section mt-4">
              Bundle Frontier Internet service with home phone
            </h1>
            <p className="paragraph_section">
              Add home phone to Frontier Internet plans to build out your home
              business hub, stay tapped into the citywide emergency grid, or
              revisit the pleasure of a retro telephone set. Then pivot to the
              futuristic speeds of Frontier Fiber. It’s all available on one
              bill when you bundle Frontier Internet and home phone today.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="planButton">Shop Frontier Bundles</button>
            </div>
           </div>
         </div> 
      </div>
      </div>
       )}
      </>
    );
  };


export default withRouter(Section4);
