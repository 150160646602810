import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Section2 from "../../Components/Section2/Section2";
import { useHistory } from "react-router-dom";
import "./Privacy.css";

const Privacy = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50;

      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [props.location]);
  const [tfn, setTfn] = useState(null);
  const handleClick = () => {
    // Check if the gtag function is defined
    if (typeof window.gtag === 'function') {
      const phoneNumber = tfn || "1-833-525-6234";

      window.gtag('event', 'conversion', {
        'send_to': 'AW-11485112475/-F_xCI_F55YZEJvRw-Qq',
        //  'event_callback': 'callback',
        'phone_number': phoneNumber,
      });

    } else {
      console.error('Google Analytics gtag function is not defined.');
    }

  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let tfnFromUrl = urlParams.get("tfn");

    if (tfnFromUrl) {
      setTfn(tfnFromUrl);
    }
  }, []);
  const getTfn = (tfn) => {
    let temp = "";
    for (let i = 0; i < tfn.length; i++) {
      if (tfn[i] !== "-") {
        temp = temp + tfn[i];
      }
    }
    return "tel:+" + temp;
  };

  const getTfnFormat = (tfn) => {
    let _tfn = "";
    tfn = tfn.split("-");
    _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
    return _tfn;
  };

  return (
    <div className="privacy_section">
      {isVisible && (
        <div className="section2">
          <Section2 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick} />
        </div>
      )}
      <div className="container">
        <h4 className="privacy_policy_head">PRIVACY POLICY</h4>
        <h6 className="privacy_policy_subhead">Purpose</h6>
        <p className="privacy_policy_para mt-3">
          The purpose of this Privacy Policy (“Privacy Policy” or “Privacy Notice”) is to summarize the ways in which Frontier Cable Internet Deals, our affiliates, and all of our brand names and branded services (collectively “Frontier Cable Internet Deals”, “we”, “our”, “us”), collects, maintains, and uses the personal information of users of this website (“Site”), who contact our customer service representatives, fill out online order forms or information request sheets, download and use browser extensions or applications created by us, and provide information to our sales agents (“user”, “you”, “your”). Please feel free to contact us by mail at Frontier Cable Internet Deals with any questions or concerns about this Privacy Notice or the information we gather about you.
        </p>
        <h6 className="privacy_policy_subhead mt-5">How We Collect Your Information</h6>
        <p className="privacy_policy_para mt-3">
          We gather information about you when you interact with our services, including when you use our websites, contact our customer service representatives, fill out online order forms or information request sheets, download and use browser extensions or applications created by us, and provide your information to our sales agents (collectively, “Customer Information”). We also use cookies and pixel tags to track your usage of our websites. We also use online order forms and information request sheets to obtain information from you that is necessary to process your orders and provide you with requested information. When you call the numbers on our websites and speak with our customer service representatives, we collect information provided during calls to ensure the best quality of service and the correct processing of your orders.
          <br />
          Customer Information we gather about you may contain personally identifiable information. For an explanation of how we use Customer Information, including personally identifiable information, please see the section below titled “How We Use Customer Information.”
        </p>
        <h6 className="privacy_policy_subhead mt-5">Information We Collect</h6>
        <p className="privacy_policy_para mt-3">
          Below is a list of the types of Customer Information that we may collect as you interact with our services:
          <ul>
            <li>Customer Contact Information. Your Customer Contact Information is any information needed to contact you and fulfill your orders and requests such as your name, address, phone number, fax number, and email address.</li>
            <li>Private Customer Information. Private Customer Information is that information that may be necessary to check credit and process payments. Such information includes your Social Security Number, credit card information, and banking and wiring information.
            </li>
            <li>
              Customer Order Information. Customer Order Information is that information necessary to complete your orders and requests for information. Such information may include your Customer Contact Information, programming and packaging selections, your billing and shipping information, as well as other details pertaining to your order of products and services through us.
            </li>
            <li>Demographic Information. Demographic Information is publicly available information that we may learn about you regarding such things as your age, income, home ownership, household size, etc. that helps us to provide you with better offers for products and services.
            </li>
            <li>Website Usage Information. Website Usage Information is information that we collect, via anonymous third-party cookies, that tells us about the way you use and navigate through our websites.
            </li>
            <li>Proprietary Analytics. This Site uses proprietary analytics systems to help improve usability and the customer experience. We may record mouse clicks, mouse movements, scrolling activity, user agent, Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, browser type and version, browser plug-in types and versions, browser extensions installed, browser features enabled, operating system and platform, information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from the Site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as mouse clicks, mouse movements, scrolling activity), methods used to browse away from the page, and other non-personally identifiable behavioral and non-behavioral information. You can choose to disable these features by electing to use the private browser setting and/or disabling javascript. Note, that doing so will disable other features that this Site may employ.</li>

          </ul>
        </p>
        <h6 className="privacy_policy_subhead mt-5">Cookies</h6>
        <p className="privacy_policy_para mt-3">
          This explains how we use cookies on this website. We recommend that you read our cookies statement so that you are aware of how we use them globally and can take steps to reject or delete the cookies if you do not agree to them being used. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the Site. You can set your browser not to accept cookies, however, in a few cases some of our website features may not function as a result.
          <br />

          We use cookies to enable you to navigate between pages and to generally improve your user experience. We also use cookies to analyze how users interact with this Website, to enable you to share content using social media and to provide you with embedded content from third parties. We also sometimes use cookie type technologies in emails to identify how users come to this Website so that we can evaluate and measure the effectiveness of our email marketing.
          <br />
          We may combine information collected via cookies with information such as your IP address, the type of browser, device, and operating system, for example, to provide statistical information about visitors to the Website. Where this Website uses third-party cookies, you should check with the relevant third party to see how they use cookies.

        </p>
        <h6 className="privacy_policy_subhead mt-5">Accepting and Rejecting Cookies</h6>
        <p className="privacy_policy_para mt-3">
          Where legally permissible, your continued use of a website after being presented with a cookie banner containing an opt-out link will be considered consent to the use of cookies. If legally required, you will be provided with a clear message about what cookies are used and their purpose and the cookie will not be deployed unless and until that consent is provided by you (e.g. clicking “accept” or “I agree”). Consent is not required under the Privacy and Electronic Communications Regulations for cookies that are strictly necessary. You can manage the use of cookies on this Website by clicking on the link provided in the “Opt-Out Procedures” below.
        </p>
        <h6 className="privacy_policy_subhead mt-5">How We Use Customer Information</h6>
        <p className="privacy_policy_para mt-3">
          We use your Customer Information to process your orders and requests and to provide you with the products and services you desire. In addition, we use information that we collect about you or that you provide to us, including any Customer Information:
          <ul>
            <li>To present our services and to otherwise carry out, evaluate, market, analyze, personalize, and improve our services, which may include storing your information and providing access and sharing of your information with our agents, vendors, suppliers, affiliates, third-party service providers, advertisers and other third parties.</li>
            <li>To operate any applications or browser extensions we create, to maintain records of your use of these applications or browser extensions, and to analyze and evaluate these applications or browser extensions to determine if we should develop and/or release additional applications or extensions.</li>
            <li>To contact you via email, SMS, and telephone calls about the Services, your account, events, services, or products that we think might be relevant or of interest to you.</li>
            <li>For any other purpose stated in this Privacy Notice.</li>
          </ul>
        </p>
        <h6 className="privacy_policy_subhead mt-5">Changes to This Privacy Notice</h6>
        <p className="privacy_policy_para mt-3">We will update this Privacy Notice from time to time to reflect changes in our business. Where required by applicable law, we will provide notice to you if these changes are material. Notice may be by email to you at the last email address you provided us, by posting notice of such changes in this Privacy Notice, on our websites or by other means, consistent with applicable law.</p>
      </div>
    </div>
  );
};
export default withRouter(Privacy);