import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Layout from "../src/Hocs/Layout";
import "./App.css";
import { customRoutes } from "./Routes/Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11485112475";
    script.addEventListener("load", () => {
      window.dataLayer = window.dataLayer || [];
      function gtagAW() {
        window.dataLayer.push(arguments);
      }
      gtagAW("js", new Date());
      gtagAW("config", "AW-11485112475");
    });
    document.body.appendChild(script);
  

  }, 
  []);
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {customRoutes.map((obj, index) => {
            return (
              <Route
                key={index}
                exact
                path={obj.path}
                component={obj.containers}
              />
            );
          })}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
