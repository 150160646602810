import React, {  useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./Section3.css";
import Banner from "../../Assets/Images/mobile.png"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Section3 = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
 
    
    return (
    <div >
      {isDesktop ? (
         <div className="row mob" style={{alignItems:"center",marginBottom:"20px", overflow:"hidden", marginLeft:"0px"}}>
         <div className="col-md-6">
       <div className="container section_space">
         <h1 className="heading_section">Frontier Home Phone service</h1>
         <p className="paragraph_section">You may think having a home phone is outdated, but there are plenty of reasons to stay connected. A home phone plan from Frontier offers unlimited nationwide calling and call management features like Voicemail, Caller ID and Call Waiting. Stay in touch and bundle internet and home phone today. Plans vary by location.</p>
    
   </div>
         </div>
       <div className="col-md-6">
       <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner"/>
       </div>
     
     </div>
      ):(
        <div className="container">
        <div className="row mob" style={{alignItems:"center",marginBottom:"20px", overflow:"hidden", }}>
        <div className="col-md-6">
        <LazyLoadImage  effect="blur"  src={Banner} alt="img" className="image_banner"/>
      </div>
        <div className="col-md-6">
      <div className="container">
        <h1 className="heading_section mt-4">Frontier Home Phone service</h1>
        <p className="paragraph_section">You may think having a home phone is outdated, but there are plenty of reasons to stay connected. A home phone plan from Frontier offers unlimited nationwide calling and call management features like Voicemail, Caller ID and Call Waiting. Stay in touch and bundle internet and home phone today. Plans vary by location.</p>
   
  </div>
        </div>
    
    
    </div>
    </div>
      )}
     
      </div>
    );

}

export default withRouter(Section3);
