import Reacts from "react";
import HomePage from "../Containers/Home/Home"
import Privacy from "../Containers/Privacy/Privacy"

export const customRoutes = [
    {
        path: "/",
        containers:HomePage 
    },
    {
        path: "/privacy-policy",
        containers: Privacy
        
    },
    // {
    //     path: "/cable",
    //     component:Home 
    // },
    // {
    //     path: "/internet",
    //     component:Home 
    // },
    // {
    //     path: "/phone",
    //     component:Home 
    // },
    // {
    //     path: "/wireless-phone",
    //     component:Home 
    // },
]