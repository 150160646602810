import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { withRouter } from "react-router";


// sidebar-collapse
const Layout = (props) => {
    // let url = props.location.pathname;
// 
    return (
        <>
            <Header />
            {props.children}
            <Footer />
        </>
    )
};

export default withRouter(Layout);