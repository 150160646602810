import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Section5.css";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaHeadphones } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";class Section5 extends Component {
  render() {
    return (
   <div className="container">

    <h4 className="card_section_header">Protect your data here, there, and everywhere</h4>
    <p  className="card_section_paragraph">Add products to deflect online threats at home and away.</p>
    <div style={{display:"flex", justifyContent:"center"}}>
    <button className="planButton"> View Plans</button>
   
    </div>
    <div className="row">
      <div className="col-md-4 col-sm-12">
        <div className="card_box">
          <IoShieldCheckmark style={{color:"#ff0037" }} size={50} />
          <h4 className="card_box_header">Total Shield<span className="special_chara">¶</span></h4>
          <p className="card_box_para">Protect your identity and online activity from evil geniuses.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <div className="card_box">
          <FaWifi style={{color:"#ff0037" }} size={50} />
          <h4 className="card_box_header">eero Secure</h4>
          <p className="card_box_para">Detects threats on your home Wi-Fi network and blocks spammy browser ads.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <div className="card_box">
          <FaHeadphones  style={{color:"#ff0037" }} size={50} />
          <h4 className="card_box_header">My Premium Tech Pro<span className="special_chara">‡</span></h4>
          <p className="card_box_para">Offers in-depth tech assistance and malware removal for most devices at home.</p>
        </div>
      </div>
    </div>
    </div>

    );
  }
}

export default withRouter(Section5);
