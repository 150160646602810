import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Header.css";
import logo from "../../Assets/Images/frontier_logo.svg"

class Header extends Component {
  state = {
    tfn:null
}
setUrl = (url) => {
let searchParams = new URLSearchParams(window.location.search);
searchParams.set("tfn", url);
let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
window.history.pushState({ path: newurl }, "", newurl);
window.scrollTo(0, 0)
this.setState({tfn:url})
}

getTfn = (tfn) => {
let temp = ""
for(let i = 0 ; i < tfn.length ; i++){
    if(tfn[i] !== "-"){
        temp = temp + tfn[i]
    }
}
return "tel:+"+temp
}

// getTfnFormat = (tfn) => {
// let _tfn = ""

// tfn = tfn.split('-')
// _tfn = "("+tfn[1]+") "+ tfn[2] + "-" + tfn[3]

// return _tfn
// }
 getTfnFormat = (tfn) => {
  let _tfn = "";
  tfn = tfn.split("-");
  _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
  return _tfn;
};
handleLogoClick = () => {
  this.props.history.push("/");
};

handleClick = () => {

  if (typeof window.gtag === 'function') {
    const phoneNumber = this.state.tfn || "1-833-525-6234"; 
    window.gtag('config', 'AW-11485112475');
    window.gtag('event', 'conversion', {
     'send_to': 'AW-11485112475/-F_xCI_F55YZEJvRw-Qq',
      //  'event_callback': 'callback',
      'phone_number': phoneNumber,
    }
    );
  
  } else {
    console.error('Google Analytics gtag function is not defined.');
  }
};
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    let tfn = urlParams.get("tfn");
    return (
      <>
        {/* <nav className="top_language pt-4">
        </nav> */}
          {/* <a className="nav_top p-2" href="">Business</a>
          <a className="border_left p-2" href="">English</a>
          <a className="border_left p-2" href="">Español</a> */}
      
        <div className="nav_red">
          <div className="container nav_red">
          <div>
            <img className="logo_img" src={logo}  onClick={this.handleLogoClick} alt="logo"/>
          </div>
          <div className="phone_div my-2">
          <a  className="px-3 py-2" onClick={this.handleClick}    href={tfn === null ? "tel:+18335256234":this.getTfn(tfn)}>Order {tfn === null? this.getTfnFormat("1-833-525-6234") :this.getTfnFormat(tfn)} </a>
            {/* <button className="px-3 py-2">Order 1-833-779-2132</button> */}
          </div>
          </div>
         </div>
      </>
    );
  }
}

export default withRouter(Header);
