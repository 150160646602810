import React, {  useState, useEffect, useRef  } from "react";
import { withRouter } from "react-router-dom";
import "./Section8.css";
import Banner from "../../Assets/Images/banner1.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Section2 = () => {

  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > 600 ? true : false
  );
  const updateDimensions = () => {
    setIsDesktop(window.innerWidth > 600 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [isDesktop]);
    return (
      <div>
           {isDesktop ? (
      <div className="row mob" style={{alignItems:"center",marginBottom:"20px", overflow:"hidden", marginLeft:"-15px"}}>
        <div className="col-md-6 mob_p">
        <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner"/>
        </div>

        <div className="col-md-6 mob_p">
        <div className="container section_space" >
          <h1 className="heading_section">Save $120 your first year of YouTube TV^</h1>
          <p className="paragraph_section">With YouTube TV, watch live TV like cable, but without long-term contracts or hidden fees. YouTube TV has 100+ channels, unlimited DVR space included, personalized recommendations, plus you get 6 accounts per household, so everyone can watch and record what they want. Start watching in minutes.</p>
          <div style={{display:"flex", justifyContent:"center"}}>
    <button className="planButton">Shop YouTube TV</button>

    </div>
    <p className="subpara_section">Limited time offer for Frontier Internet subscribers who are first-time YouTube TV customers. Terms apply.^</p>
    </div>
          </div>
      </div>
           ):(
            <div className="container mt-4">
            <div className="row mob" style={{alignItems:"center",marginBottom:"20px", overflow:"hidden", marginLeft:"-15px"}}>
            <div className="col-md-6 mob_p">
            <LazyLoadImage  effect="blur" src={Banner} alt="img" className="image_banner"/>
            </div>
            <div className="col-md-6 mob_p">
            <div className="container">
              <h1 className="heading_section mt-4">Save $120 your first year of YouTube TV^</h1>
              <p className="paragraph_section">With YouTube TV, watch live TV like cable, but without long-term contracts or hidden fees. YouTube TV has 100+ channels, unlimited DVR space included, personalized recommendations, plus you get 6 accounts per household, so everyone can watch and record what they want. Start watching in minutes.</p>
              <div style={{display:"flex", justifyContent:"center"}}>
        <button className="planButton">Shop YouTube TV</button>
    
        </div>
        <p className="subpara_section">Limited time offer for Frontier Internet subscribers who are first-time YouTube TV customers. Terms apply.^</p>
        </div>
              </div>
          </div>
          </div>
           )}
      </div>
    );
  
};

export default withRouter(Section2);
